body {
  font: #{map-deep-get($font, text, weight)} #{map-deep-get($font, text, size)}/#{map-deep-get($font, text, line-height)} #{map-deep-get($font, text, family)};
  min-width: map-deep-get($grid, breakpounts, xs);
}

$values: map-get($font, titles);
$size: map-get($values, size);
$result: font-sizes($values, $size);

@each $name, $size in sort($result) {
  #{$name},
  .#{$name} {
    font: #{map-get($values, weight)} #{$size}/#{map-get($values, line-height)} #{map-get($values, family)};
  }
}

a {
  color: var(--color-theme-third);

  &:hover,
  &:focus {
    color: var(--color-theme-second);
  }
}

.btn__white {
  &.btn__light {
    background-color: transparent;
    color: var(--white) !important;
    border: 1px solid var(--white);

    &:hover,
    &:focus {
      background-color: transparent;
      color: var(--color-theme-third) !important;
      border: em(1px) solid var(--color-theme-third);
    }

    &.btn__filled {
      &:hover,
      &:focus {
        background-color: var(--color-theme-third);
        color: var(--white) !important;
        border: em(1px) solid var(--color-theme-third);
      }
    }
  }
}

.btn__orange {
  background-color: var(--color-theme-second);
  color: var(--white) !important;
}


.btn__blue {
  background-color: var(--color-theme-third);
  color: var(--white) !important;

  &:hover,
  &:focus {
    background-color: var(--color-theme-second);
    color: var(--white) !important;
  }

  &.btn__light {
    background-color: transparent;
    color: var(--color-theme-third) !important;
    border: em(1px) solid var(--color-theme-third);

    &:hover,
    &:focus {
      background-color: transparent;
      color: var(--color-theme-second) !important;
      border: em(1px) solid var(--color-theme-second);
    }
  }
}