@use "sass:math";

@mixin media-width($name)
{
  $with: map-deep-get($config, media-width, $name);

  @if ($with){
    @media (min-width: #{$with}){
      @content;
    }
  }
}

@mixin adaptive-value($property, $minSize, $startSize, $type) {
  $addSize: strip-units($startSize) - strip-units($minSize);

  $minWidth: strip-units(map-deep-get($config, adaptive-value, min-witdh));
  $defaultWidth: strip-units(map-deep-get($config, adaptive-value, default-witdh));
  $maxWidth: strip-units(map-deep-get($config, adaptive-value, max-witdh));

  @if ($type == 1) {
    // Если меньше контейнера
    #{$property}: $startSize;
    @media (max-width: #{$maxWidth+em}) {
      #{$property}: calc(#{$minSize} + #{$addSize} * ((100vw - #{$minWidth+em}) / #{$maxWidth - $minWidth}));
    }

  } @else if ($type == 2) {
    // Если больше контейнера
    #{$property}: $startSize;
    @media (min-width: #{$minWidth+em}) {
      #{$property}: calc(#{$minSize} + #{$addSize} * ((100vw - #{$minWidth+em}) / #{$maxWidth - $minWidth}));
    }

  } @else if ($type == 3) {
    // Если в рамках диапазона контейнеров
    $addSize: $addSize * math.div($maxWidth - $minWidth, $defaultWidth - $minWidth);

    #{$property}: $minSize;

    @media (min-width: #{$minWidth+em}) and (max-width: #{$maxWidth+em}){
      #{$property}: calc(#{$minSize} + #{$addSize} * ((100vw - #{$minWidth+em}) / #{$maxWidth - $minWidth}));
    }

    @media (min-width: #{$maxWidth+em}) {
      #{$property}: $minSize+$addSize;
    }

  } @else if ($type == 4) {
    // Если в рамках диапазона контейнеров максмальная величина (увеличение)
    #{$property}: $minSize;

    @media (min-width: #{$minWidth+em}) and (max-width: #{$maxWidth+em}){
      #{$property}: calc(#{$minSize} + #{$addSize} * ((100vw - #{$minWidth+em}) / #{$maxWidth - $minWidth}));
    }

    @media (min-width: #{$maxWidth+em}) {
      #{$property}: $minSize+$addSize;
    }

  } @else if ($type == 5) {
    // Если в рамках диапазона контейнеров максмальная величина (уменьшение)
    #{$property}: $minSize; //90 -30

    @media (min-width: #{$minWidth+em}) and (max-width: #{$maxWidth+em}){
      //#{$property}: calc(#{$minSize} + #{$addSize} * ((100vw - #{$minWidth+em}) / #{$maxWidth - $minWidth}));


      //#{$property}: calc((100vw - #{$minWidth+em}) / #{$maxWidth - $minWidth});
      //90 - 30 * 96  (100vw -20em) * 67.5 =12

      // ((100vw - #{$minWidth+em}) / #{$maxWidth - $minWidth})
      //--full-width: calc(100vw - #{$minWidth+em});  //1200 - 320 = 980 900 - 320 = 580 //600 - 320 = 280 //320 - 320 = 0
      //--diapazon: #{$maxWidth - $minWidth};         //1200 - 320 = 980
                                                    //              1             0.59              0.28               0
                                                    //              1             1                 1                  1
      //(1 - (100vw - #{$minWidth+em}) / #{$maxWidth - $minWidth})
      //calc(60% + 30 * ((100vw - 20em)/ 67.5))
      //90   60+17.7=77.7   60+8.4=68.4    60+0=60

      @if ($addSize > 0){
        #{$property}: calc(#{$minSize} + #{$addSize} * ((100vw - #{$minWidth+em}) / #{$maxWidth - $minWidth}));
      } @else {
        $addSizeModule: $addSize * -1;
        #{$property}: calc(#{$minSize+$addSize} + #{$addSizeModule} * ((100vw - #{$minWidth+em}) / #{$maxWidth - $minWidth}));
      }

    }

    @media (min-width: #{$maxWidth+em}) {
      #{$property}: $minSize+$addSize; //60
    }

  } @else {
    // Всегда
    #{$property}: calc(#{$minSize} + #{$addSize} * ((100vw - #{$minWidth+em}) / #{$maxWidth - $minWidth}));
  }
}