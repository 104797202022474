@import "sys_components/variables";
@import "sys_components/base";

.section {
  padding: 4vw 0;
  position: relative;
}

.section__title {
  padding: 3em .5rem;
  text-align: center;

  .title {
    //color: var(--color-theme-first);
  }

  .description {
    display: inline-block;
    font-size: var(--font-h6-size);
    color: var(--color-text-dark-additional);
    margin-top: 1em;
  }
}

.section__bottom {
  text-align: center;
  padding: 3em;

  .description {
    margin-top: 1em;
    display: block;
    color: var(--color-text-dark-additional);
  }
}

.background-grey {
  background-color: var(--color-text-light-additional);
}

.background-blue {
  background-color: var(--color-theme-third);
}


.background-green {
  background-color: var(--color-theme-first);
}

/*
.background-orange {
  background-color: var(--color-theme-second);
}
*/

/*
.block-background-grey:before{
  background-color: #f0efef;
  content: "";
  display: block;
  position: absolute;
  left: 50%;
  top: 0;
  height: 100%;
  width: 100%;
  transform: translateX(-50%);
  z-index: -1;
}
*/

.section--type--0 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  position: relative;
  width: 100%;
  height: auto;
  min-height: calc(100vh - em(64px));
  padding: 0;

  .section__background {
    z-index: -1;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &:after {
      position: absolute;
      top: 0;
      left: 0;
      content: "";
      width: 100%;
      height: 100%;
      background-color: var(--color-theme-third);
      opacity: .9;
    }
  }

  .section__content {
    @include adaptive-value("width", 90%, 60%, 4);
    text-align: center;
    margin-top: 10%;

    .content__title {
      @include adaptive-value("font-size", em(47.7757px), em(72px), 4);
      color: var(--white);
      line-height: 1.1;

      .title__highlight {
        color: var(--color-theme-second);
      }

      .title__sup {
        vertical-align: super;
        font-size: .32768em;
      }
    }

    .content__description {
      @include adaptive-value("font-size", em(16px), em(19.2px), 4);
      line-height: 1.6;
      color: var(--white);
      margin-top: em(25px);
    }

    .content__btn {
      margin-top: em(25px);
      font-size: var(--font-h6-size);
      padding: em(8px) em(48px);
      border: em(1px) solid transparent;

      &:hover,
      &:focus {
        border: em(1px) solid var(--white);
      }
    }

    .content__btn__description {
      margin-top: em(15px);
      color: var(--white);
    }
  }

  .section__bottom {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-auto-rows: 1fr;
    @include adaptive-value("width", 90%, 60%, 4);
    padding: 2em 3em;

    .item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .item__img {
      width: 30%;
      height: 45%;

      svg {
        fill: var(--white);
        max-height: 100%;
      }
    }

    .item__title {
      color: var(--white);
      margin-top: em(16px);
    }

    @include media-width('md') {
      grid-template-columns: repeat(3, 1fr);

      .item__title {
        height: calc(var(--font-standart-size) * 2 * 1.6);
        margin-top: em(16px);
      }
    }
  }
}

.section--type--1 {
  .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 1fr;
    width: 90%;
    margin: 0 auto;

    .item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &:not(:nth-child(2n)) {
        border-right: 1px solid #e1e2e6;
      }

      &:not(:nth-child(-n+2)) {
        border-top: 1px solid #e1e2e6;
      }
    }

    @include media-width('md') {
      grid-template-columns: repeat(4, 1fr);

      .item {
        &:not(:nth-child(-n+2)) {
          border-top: none;
        }

        &:not(:nth-child(4n)) {
          border-right: 1px solid #e1e2e6;
        }

        &:not(:nth-child(-n+4)) {
          border-top: 1px solid #e1e2e6;
        }
      }
    }

    .item__img {
      width: 40%;

      svg {
        fill: var(--color-theme-first);
      }
    }

    .item__title {
      font-size: var(--font-h6-size);
      text-align: center;
      height: 3em;
    }
  }
}

.section--type--2 {
  .row {
    display: flex;
    align-items: center;
  }

  .photo {
    text-align: center;
  }

  .photo__img {
    padding: 10%;
  }

  .text {
    padding: 0 3vw;
    text-align: center;

    .title {
      font-family: var(--font-h2-family);
      font-size: var(--font-h2-size);
      line-height: 1.2;
      color: var(--white);
      margin-bottom: rem(24px);
    }

    .description {
      font-family: var(--font-h6-family);
      font-size: var(--font-h6-size);
      line-height: 1.4;
      color: var(--white);
      margin-bottom: rem(24px);
    }

    .btn {
      display: inline-block;
      padding: em(11px) em(40px);
      font-size: var(--font-h6-size);
      margin-bottom: rem(24px);
    }

    @include media-width('lg') {
      text-align: left;
    }
  }
}

.section--type--3 {
  .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 1fr;
    margin: 0 auto;

    .item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 10% 5%;
    }

    .item__count {
      color: var(--color-theme-first);
      font-size: var(--font-h2-size);
      //font-weight: 600;
    }

    .item__title {
      font-size: var(--font-h6-size);
      text-align: center;
      height: calc(var(--font-h6-size) * 2 * 1.2);
      margin-top: 5%;
      word-wrap: anywhere;
    }

    @include media-width('md') {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}

.section--type--5 {
  .grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-auto-rows: 1fr;
    width: 90%;
    margin: 0 auto;

    .item {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      background-color: var(--color-text-light-additional);
      border: em(1px) solid var(--color-theme-third);
      border-radius: em(26px);
      margin: em(12px);
      padding: em(24px);
    }

    .item__img {
      width: 30%;
      height: em(64px);
      fill: var(--color-theme-third);

      svg {
        max-height: 100%;
      }
    }

    .item__title {
      width: 70%;
      font-weight: 600;
      padding: 0 em(16px);
    }

    .item__description {
      padding-top: em(16px);
    }

    @include media-width('sm') {
      grid-template-columns: repeat(2, 1fr);
    }

    @include media-width('lg') {
      grid-template-columns: repeat(3, 1fr);
    }

    @include media-width('xl') {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}

.section--type--6 {
  .grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-auto-rows: 1fr;
    width: 90%;
    margin: 0 auto;

    .item {
      display: flex;
      flex-direction: row;
      justify-content: center;
      background-color: var(--color-text-light-additional);
      border: em(1px) solid var(--color-theme-third);
      border-radius: em(26px);
      margin: em(12px);
      padding: em(8px);
      aspect-ratio: 4 / 1;
    }

    .item__img {
      width: 30%;
      fill: var(--color-theme-third);

      svg {
        max-height: 100%;
      }
    }

    @include media-width('sm') {
      grid-template-columns: repeat(2, 1fr);
    }

    @include media-width('lg') {
      grid-template-columns: repeat(3, 1fr);
    }

    @include media-width('xl') {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}